<template>
  <div class="map-block">
    <div id="map" class="map" />
  </div>
</template>

<script>
import ymaps from "ymaps";
import { myMixin } from "./map-function.js";

export default {
  name: "HomeMap",
  mixins: [myMixin],
  props: {
    points: {
      type: Array,
      default: () => [],
    },
  },
  async mounted() {
    await ymaps
      .load(`https://api-maps.yandex.ru/2.1/?apikey=${process.env.VUE_APP_YMAPS_APIKEY}&lang=ru_RU`)
      .then((maps) => {
        this.map = new maps.Map(
          "map",
          {
            center: this.points[0][0] ? this.points[0] : [42.48422866048148, 69.51566381073658],
            zoom: 7,
            controls: ["geolocationControl", "zoomControl"],
          },
          {
            geolocationControlFloat: "right",
            geolocationControlPosition: {
              bottom: "200px",
              right: "10px",
            },
            geolocationControl: "none",
            zoomControlFloat: "none",
            zoomControlPosition: {
              bottom: "125px",
              right: "10px",
            },
            zoomControlSize: "small",
          }
        );
        if (this.points) {
          if (this.points[1][0] && this.points[1][1]) {
            this.route(maps, this.points);
          }
          this.pointsIcon(maps, this.points);
        }
      })
      .catch((error) => console.log("Failed to load Yandex Maps", error));
  },
};
</script>

<style scoped>
.map-block {
  max-width: 100%;
  max-height: 100%;
  /* height: 300px;
  width: 400px; */
  position: relative;
}

.map {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.map__control {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

@media (max-width: 579px) {
  .map-block {
    width: 100%;
  }

  .map {
    height: calc(100% - 50px);
  }
}
</style>
